<template>
  <base-web-page>
    <template v-slot:main>
      <el-main style="padding:30px 20px;margin:0 auto;width:100%;max-width:1300px;text-align:left;">
        <h1 style="padding-bottom:40px;text-align:center;">1UClub - A UShop &amp; UCommune(SG) Membership App</h1>
        <div style="margin: 40px 0px;display:-webkit-box;">
          <div>
            <div>
              <el-link :class="mobile=='android'?'checked':''" :underline="false" @click="moblieClick('android')">
                <h1>Android</h1>
              </el-link>
            </div>
            <div>
              <el-link :class="mobile=='iPhone'?'checked':''" :underline="false" @click="moblieClick('iPhone')">
                <h1>iPhone</h1>
              </el-link>
            </div>
          </div>
          <div style="margin-left:100px; width:280px;">
            <div v-if="mobile=='android'">
              <h1>UClub Android App</h1>
              <p style="color:grey;">V1.0.0 | 96M | 2021-08-03 </p>
              <el-button type="danger" round style="width:200px;">Download</el-button>
            </div>
            <div v-else>
              <h1>UClub iPhone App</h1>
              <p style="color:grey;">V1.0.0 | 107M | 2021-08-03 </p>
              <el-button type="danger" round style="width:200px;">Download</el-button>
            </div>
          </div>
          <div style="margin-left:100px;">
            <el-image class="app-img" :src="require('../../assets/uclub_app.png')"></el-image>
          </div>
          <div style="margin-left:100px;">
            <el-image class="download-img" :src="require('../../assets/uclub_download.png')"></el-image>
          </div>
        </div>
      </el-main>
    </template>
  </base-web-page>
</template>

<script>
import BaseWebPage from '../../components/base/BaseWebPage.vue'
export default {
  components: { BaseWebPage },
  data () {
    return {
      mobile: 'android'
    }
  },
  methods: {
    moblieClick(val){
      this.mobile = val
    }
  }
}
</script>

<style scoped>
.app-img {
  width: 300px;
}
.download-img {
  border: 1px solid #DCDFE6;
  box-shadow: #DCDFE6 0px 5px 5px 1px;
  width: 200px;
  margin-top: 150px;
}
.checked {
  color: #409EFF;
  text-decoration: underline;
}
</style>